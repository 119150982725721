// @nebular theming framework
@import '@nebular/theme/styles/theming';
// @nebular out of the box themes
@import '@nebular/theme/styles/themes';
@import "@nebular/theme/styles/core/functions";

// material themes
@import './material/material-dark';
@import './material/material-light';

$nb-enabled-themes: ('material-light', 'material-dark');

$nb-themes: nb-register-theme((
  layout-padding-top: 2.25rem,

  menu-item-icon-margin: 0 0.5rem 0 0,

  card-height-tiny: 13.5rem,
  card-height-small: 21.1875rem,
  card-height-medium: 28.875rem,
  card-height-large: 36.5625rem,
  card-height-giant: 44.25rem,
  card-margin-bottom: 1.875rem,
  card-header-with-select-padding-top: 0.5rem,
  card-header-with-select-padding-bottom: 0.5rem,

  select-min-width: 6rem,

  option-list-border-radius: 0,

  slide-out-background: linear-gradient(270deg, #e0e0e0 0%, #ebebeb 100%),
  slide-out-shadow-color: 0 4px 14px 0 #ebebeb,
  slide-out-shadow-color-rtl: 0 4px 14px 0 #ebebeb,

  form-control-color: #495057,
), material-light, material-light);

$nb-themes: nb-register-theme((
  layout-padding-top: 2.25rem,

  menu-item-icon-margin: 0 0.5rem 0 0,

  card-height-tiny: 13.5rem,
  card-height-small: 21.1875rem,
  card-height-medium: 28.875rem,
  card-height-large: 36.5625rem,
  card-height-giant: 44.25rem,
  card-margin-bottom: 1.875rem,
  card-header-with-select-padding-top: 0.5rem,
  card-header-with-select-padding-bottom: 0.5rem,

  select-min-width: 6rem,

  option-list-border-radius: 0,

  slide-out-background: linear-gradient(270deg, #1f1f1f 0%, #292929 100%),
  slide-out-shadow-color: 0 4px 14px 0 #292929,
  slide-out-shadow-color-rtl: 0 4px 14px 0 #292929,

  form-control-color: #495057,
), material-dark, material-dark);

.mat-dialog-container {
  padding: 0 10px !important;
}